<template>
  <v-container class="bg-glass-effect px-3 pt-10" fluid>
     <BackArrow style="position: initial"/>
    <v-row  class="px-3">
      <v-col class="pt-7 pb-0 ml-2" cols="12">
        <span class="generic-title ">Detalhes</span>
      </v-col>
      <v-col>
        <v-row col-12 class="mt-5 ml-2">
          <v-row class="col-12 align-center pb-1 ">
            <div class="mr-2">
              <v-img width="2vw" v-if="this.creditCardBrand" :src="this.creditCardBrand"></v-img>
            </div>
            <div>
              <span>&#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022; {{ transaction.cardNumber}}</span>
            </div>
          </v-row>
          <v-row class="col-12 ">
            <span class="mr-2">Status: {{this.transaction.statusDescription}} </span>
            <v-icon v-if="this.transaction.status === 'denied' || this.transaction.status === 'notSend' || this.transaction.status === 'processError'" medium color="#D9FF00FF">fas fa-exclamation-circle</v-icon>
            <v-icon v-if="this.transaction.status === 'captured' || this.transaction.status === 'authorized'" medium color=" green darken-2">fas fa-check-circle</v-icon>
          </v-row>
          <v-row class="col-12">
            <span>Data: {{transaction.fullDate}}</span>
          </v-row>
          <v-row class="col-12">
            <span>Valor: R$ {{ transaction.value}}</span>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import BackArrow from "@/components/covid/back-arrow";
import SubscriptionService from "@/services/odoo/SubscriptionService";
import moment from "moment";


export default {
  data: () => ({
    transaction: {
      date: null,
      status: null,
      value: null,
      cardBrand: null,
      cardNumber: null
    },
    subscriptionId: null,
    creditCardBrand: null
  }),
 components: {
    BackArrow,
    //GenericBtn,
    //DatePicker
  },
  created() {
    this.subscriptionService = new SubscriptionService();
    if(this.$route.params && this.$route.params.transaction) {
      this.transaction = this.$route.params.transaction;
      this.getCreditCardBrandAndCardNumber()
    } else if(this.$route.query && this.$route.query.transactionId){
      this.getTransactionById()
    }
  },
  methods: {
    ...mapMutations(["showmsg", "loading", "updateMenuState", "toogleMainDialog"]),
    getTransactionById() {
      this.loading(true)
      this.subscriptionService.getTransactionById(this.$route.query.transactionId).then((response) => {
        let data = response.data
        this.transaction.fullDate = moment(data.payday).format("DD/MM/yyyy")
        this.transaction.value = this.$util.formatCurrencyToBR(data.value / 100)
        this.transaction.status = data.current_status
        this.transaction.statusDescription = data.current_status_description
        this.transaction.cardBrand = data.card_brand
        this.transaction.cardNumber = data.card_number
        this.getCreditCardBrandAndCardNumber()
      }).catch(() => {
        this.showmsg({text: "Erro ao carregar pagamento", type: "error"});
      }).finally(() => {
        this.loading(false)
      });
    },
    getCreditCardBrandAndCardNumber(){
      if(this.transaction != null){
        if(this.transaction.cardNumber != null) {
          let size = this.transaction.cardNumber.length;
          this.transaction.cardNumber = this.transaction.cardNumber.substring(size - 4, size)
        }
        if(this.transaction.cardBrand != null){
          switch (this.transaction.cardBrand.toLowerCase()){
            case 'visa':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_visa.png'
              break;
            case 'amex':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_american_express.png'
              break;
            case 'hipercard':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_hiper_card.png'
              break;
            case 'mastercard':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_master_card.png'
              break;
            case 'elo':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_elo.png'
              break;
          }
        }
      }
    }
  },

  computed: {
    ...mapState(["menu", "app"]),
    menur: {
      get: function () {
        return this.menu.rigth;
      },
      set: function (value) {
        this.updateMenuState({ rigth: value });
      },
    },
  },

};
</script>
